<template>
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[4]}}</span>
			</div>

			<div class="content">
				<div class="head">
					<div class="left">
						<div class="current">
							<span class="openTitle">{{$t('common.currentOpen')}}：</span>
							<span class="exchange">{{exchange}}</span>
							<span class="pair" v-if="web!='opensea'">{{yenUnit1.toUpperCase()+'/'+yenUnit2.toUpperCase()}}</span>
							<span class="pair" v-else>{{yenUnit1.toUpperCase()}}</span>
						</div>
						<span class="g_btn btn" @click="openPopCollect">{{$t('common.selectOpen')}}</span>
					</div>
					<div class="right">
						<el-radio-group v-model="date_select" @change="switchTime">
							<el-radio-button label="7">{{$t('data.recent7')}}</el-radio-button>
							<el-radio-button label="30">{{$t('data.recent30')}}</el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div class="content-container" v-if="web!='opensea'">
					
					<div class="data_list">
						<div class="item">
							<div class="title_box">
								<div class="chartbar_title">
									{{$t('data.dailyTrading')}}
									<span>（{{$t('data.endTime')+getBeforeDate(1)}}）</span>
								</div>
								<div class="g_btn export" @click="exportExcel">{{$t('data.export')}}</div>
							</div>
							<!-- <div class="g_btn export" @click="exportExcel">{{$t('data.export')}}</div> -->
							<div class="item_box">
								<div class="chart" id="chartbar" ref="chartbar"></div>
							</div>
						</div>
						<div class="item">
							<div class="item_box">
								<div class="chart" id="chartcircle" ref="chartcircle"></div>
								<div class="title">{{$t('data.allocation')}}</div>
								<div class="sub" v-show="pieNoData==1">{{$t('common.noData')}}</div>
							</div>
						</div>
						<div class="item">
							<div class="item_box">
								<div class="chart" id="chartline" ref="chartline"></div>
							</div>
						</div>
						
						<!-- <chartLine ref="chart_line_one" /> -->
					</div>
					
				</div>

				<div class="opensea" v-else>
					<!-- {{$t('data.nftWait2')}} -->
				</div>
			</div>
		</template>
		<template  v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="head">
					<div class="left">
						<div class="current">
							<span class="openTitle">{{$t('common.currentOpen')}}：</span>
							<span class="exchange">{{exchange}}</span>
							<span class="pair" v-if="web!='opensea'">{{yenUnit1.toUpperCase()+'/'+yenUnit2.toUpperCase()}}</span>
							<span class="pair" v-else>{{yenUnit1.toUpperCase()}}</span>
						</div>
						<span class="g_btn btn" @click="openPopCollect">{{$t('common.selectOpen')}}</span>
					</div>
					<div class="right">
						<el-radio-group v-model="date_select" @change="switchTime">
							<el-radio-button :label="7">{{$t('data.recent7')}}</el-radio-button>
							<el-radio-button :label="30">{{$t('data.recent30')}}</el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div class="content-container" v-if="web!='opensea'">
					
					<div class="data_list">
						<div class="item">
							<div class="title_box">
								<div class="chartbar_title">
									{{$t('data.dailyTrading')}}
									<span>（{{$t('data.endTime')+getBeforeDate(1)}}）</span>
								</div>
								<div class="g_btn export" @click="exportExcel">{{$t('data.export')}}</div>
							</div>
							<!-- <div class="g_btn export" @click="exportExcel">{{$t('data.export')}}</div> -->
							<div class="item_box">
								<div class="chart" id="chartbar" ref="chartbar"></div>
							</div>
						</div>
						<div class="item">
							<div class="item_box">
								<div class="chart" id="chartcircle" ref="chartcircle"></div>
								<div class="title">{{$t('data.allocation')}}</div>
							</div>
						</div>
						<div class="item">
							<div class="item_box">
								<div class="chart" id="chartline" ref="chartline"></div>
							</div>
						</div>
						
						<!-- <chartLine ref="chart_line_one" /> -->
					</div>
					
				</div>
				
				<div class="opensea" v-else>
					<!-- {{$t('data.nftWait2')}} -->
				</div>
			</div>
		</template>

		<!-- 交易所选择 -->
		<exchange-dialog
			:show.sync="popExchange"
			:web="web"
			@close="closeExchange"
			@select="handleSelect"
		/>
		<!-- 选择盘口 常用盘口 -->
		<el-dialog class="collect" :title="$t('common.selectOpen')" center :visible.sync="popCollection" :show-close="false">
			<div class="title">{{$t('common.commonly')}}</div>
			<div class="company_box">
				<div class="tab_title">
					<div class="left">{{$t('common.exchange')}}</div>
					<div class="right">{{$t('common.pair')}}</div>
				</div>
				<div class="item" v-for="(item,index) in collectList" :key="index" @click="commonlyTc(item)">
					<div class="left">{{item.exchange_name}}</div>
					<div class="right">
						<div>{{item.currency}}</div>
						<div class="collect_btn" @click.stop="collectCancel(item.currency,item.web_id,item.web)">
							<img src="@/assets/images/collection_active.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="title">{{$t('stocks.manual')}}</div>
			<div class="manual_box">
				<div class="select" @click="popExchange = true">
					<input v-model="popexchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
					<i class="iconfont icon-down"></i>
				</div>
				<div class="pair" v-if="popweb!='opensea'">
					<input v-model="popyenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
					<i class="split"></i>
					<input v-model="popyenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
				</div>
				<div class="pair opensea" v-else>
					<input v-model="popyenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
				</div>
			</div>
			<div class="foot">
				<span class="g_btn btn" @click="popCollection=false">{{$t('common.back')}}</span>
				<span class="g_btn btn submit" @click="handleInConfirm">{{$t('common.confirm')}}</span>
			</div>
		</el-dialog>

		<!-- 绑定邮箱 -->
		<el-dialog :title="$t('user.bindEmail')" :visible.sync="showEmail" :show-close="false" center :before-close="handleCancel">
			<div class="add_form">
				<div class="form">
					<div class="item">
						<div class="title">{{$t('common.newEmail')}}</div>
						<div class="text">
							<input
								class="inp"
								:placeholder="$t('common.input')"
								v-model="form.email"
							/>
						</div>
					</div>
					<div class="item">
						<div class="title">{{$t('common.verification')}}</div>
						<div class="text">
							<div class="inp_code">
								<input
									class="inp"
									:placeholder="$t('common.inputVerification')"
									type="text"
									v-model="form.sms_code"
								/>
								<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
								<div v-else class="g_btn btn" @click="handleSendCode('email')">
									<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
									<template v-else>{{$t('common.resend')}}</template>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="foot">
					<div class="btn" @click="handleCancel">{{$t('common.cancel')}}</div>
					<div class="btn submit" @click="editEmail">{{$t('common.confirm2')}}</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'index.styl';
</style>

<script>
	var timer;
	import { Loading } from 'element-ui';
	import ExchangeDialog from '@/components/exchange.vue';
	
	import {mapGetters} from 'vuex'
	// api
	import {collectCancel} from '@/api/markets'
	import {
		getVolumeRecords,
		getAssetsRatio,
		getAssetsRecord,
		exportRecordsSend,
	} from '@/api/data'
	import {sendEmail,updateEmail} from '@/api/user'
	let that
	export default ({
		name: 'assets',
		components: {
			ExchangeDialog,
		},
		data() {
			return {
				loading:'',

				myChart1:'',
				myChart2:'',
				myChart3:'',
				
				// 常用盘口
				popCollection:false,
				isCollection:false,
				collect_id:'',//收藏id
				// collectList:[],
				popexchange: localStorage.getItem('exchange') || (localStorage.getItem('lang')=='zh'?'币安':'bian'),
				popweb: localStorage.getItem('web') || 'bian',
				popyenUnit1: localStorage.getItem('yenUnit1') || 'btc',
				popyenUnit2: localStorage.getItem('yenUnit2') || 'usdt',
				popweb_id: localStorage.getItem('web_id')||'5',

				// 交易所选择
				popExchange: false,
				exchange: localStorage.getItem('exchange') || (localStorage.getItem('lang')=='zh'?'币安':'bian'),
				web: localStorage.getItem('web') || 'bian',
				yenUnit1: localStorage.getItem('yenUnit1') || 'btc',
				yenUnit2: localStorage.getItem('yenUnit2') || 'usdt',
				web_id: localStorage.getItem('web_id')||'5',

				// 时间单选
				date_select:7,
				// startYear:'',
				// endYear:'',
				dateArr:[],//日期数据 X轴
				// bar
				buyData:[],
				sellData:[],
				gasData:[],
				coin_unit:'',
				gas_unit:'',
				// pie
				pieData:[
					// { value: 735, name: 'USDT',num:735},
					// { value: 580, name: 'FIL',num:580},
					// { value: 100, name: 'EOS',num:100}
				],
				pieNoData:0,//0有数据 1没数据
				// line
				lineLegendData:[
					// 'ETH', 'USDT', 'EOS'
				],
				lineData:[
					// {
					// 	name: 'ETH',
					// 	type: 'line',
					// 	data: [12, 23, 10, 24, 19, 20, 10, 23, 20, 24]
					// },
					// {
					// 	name: 'USDT',
					// 	type: 'line',
					// 	data: [2200, 1800, 1900, 2300, 2900, 3300, 3100, 1800, 1900, 2300]
					// },
					// {
					// 	name: 'EOS',
					// 	type: 'line',
					// 	data: [0.1, 23, 5, 85, 0.02, 33, 21, 23, 10, 15]
					// },
				],
				lineType:'value',//log value
				// zero:0.1,//log模式下0对应的值

				showEmail:false,
				codeSending: false,
				timeCount: 60,
				firstSend: true,
				form: {},
			}
		},
		created(){
			that=this;
			this.loading = Loading.service({
				target: '.main',
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'apploading',
			});

			// 获取日期数据 X轴
			this.changeTime()
			// 获取收藏列表
			this.getCollectList()

			// echarts init
			if(this.web!='opensea'){
				this.echartsInitAll(3)
			}else{
				this.loading.close();

				this.$confirm(this.$t('data.nftWait2'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.back'),
					// showConfirmButton:false,
					center: true,
					showClose: false,
					customClass: 'deleteOk',
					showCancelButton:false,
					// cancelButtonText: this.$t('common.back'),
				}).then(() => {
					this.$router.go(-1);
				})
			}
		},
		computed:{
			...mapGetters(['account','collectList','lang','theme','isMobile','email']),
			// timeRange(){
			// 	return that.startYear==that.endYear?that.startYear:that.startYear+'-'+that.endYear
			// },
		},
		methods: {
			// 获取详情
			getInfo(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				this.$store.dispatch('user/getInfo').then(res=>{
					loading.close();
				}).catch(err=>{
					loading.close();
				})
			},
			// 发送验证码
			handleSendCode(type) {
				if(!this.codeSending){
					var email;
					// 修改邮箱
					var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
					// /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
					if(!this.form.email){
						this.$message({
							message: this.$t('tip.emailEmpty')+'！',
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
						return
					}else if(!emailReg.test(this.form.email) ){
						this.$message({
							message: this.$t('tip.emailVer')+'！',
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
						return
					}else if(this.form.email==this.email){
						this.$message({
							message: this.$t('tip.emailRepeat'),
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
						return
					}else{
						email=this.form.email
						// 发送验证码
						this.timeCount = 60;
						this.codeSending = true;
						let that = this;
						this.firstSend = false;
						clearInterval(timer);
						timer = setInterval(() => {
							if(this.timeCount >= 1) {
								that.timeCount -= 1;
							} else {
								clearInterval(timer);
								this.codeSending = false;
							}
						}, 1000);

						sendEmail({
							account:this.account,
							email,
							type:2,
						})
					}
				}
			},
			handleCancel() {
				// this.showPhone = false;
				this.showEmail = false;
				this.form = {};
				
				clearInterval(timer);
				this.codeSending = false;
				this.firstSend = true;
			},
			// 绑定邮箱提交
			editEmail(){
            	var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
				// /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				if(!this.form.email){
					this.$message({
						message: this.$t('tip.emailEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!emailReg.test(this.form.email) ){
					this.$message({
						message: this.$t('tip.emailVer')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.sms_code){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					updateEmail({
						account:this.account,
						email:this.form.email,
						sms_code:this.form.sms_code,
						type:!!this.email? 1:0,
					}).then(res=>{
						if(res==1){
							this.getInfo()
							// 修改成功
							this.$message({
								message: this.$t('tip.editSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							this.showEmail = false;
							this.form = {};
							
							clearInterval(timer);
							this.codeSending = false;
							this.firstSend = true;
						}else{
							// 修改失败
							this.$message({
								message: this.$t('tip.editErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
					})
				}
			},
			// 导出交易明细
			exportExcel(){
				// console.log(this.email)
				if(this.email){
					// 交易明细将发送至您的邮箱，请注意查收
					this.$confirm(this.$t('tip.exportSend'),this.$t('tip.tips'),{
						confirmButtonText: this.$t('common.confirm'),
						cancelButtonText: this.$t('common.cancel'),
						center: true,
						customClass: 'deleteOk',
						showClose:false,
						// showCancelButton:false,
					}).then(() => {
						exportRecordsSend({
							web:this.web,
							currency:this.yenUnit1+'_'+this.yenUnit2,
							account:this.account
						}).then(()=>{
							this.$message({
								message: this.$t('tip.sendSuc'),
								center: true,
								type: 'success',
								customClass: 'shotMsg',
							});
						})
					})
				}else{
					// 请您绑定邮箱
					this.$confirm(this.$t('tip.noEmail'),this.$t('tip.tips'),{
						confirmButtonText: this.$t('common.toBind'),
						cancelButtonText: this.$t('common.cancel'),
						center: true,
						customClass: 'deleteOk',
						showClose:false,
					}).then(() => {
						this.showEmail=true
						// if(this.isMobile==0){
						// 	this.$router.push('/user')
						// }else{
						// 	this.$router.push('/centerSafe')
						// }
					})
				}
			},
			// 获取n天前的日期
			getBeforeDate(days){
				var now=new Date().getTime();
					var ago=now-86400000*days;//一天的毫秒数为86400000
					var agoData= new Date(ago);
					var year = agoData.getFullYear();
					var mon = agoData.getMonth() + 1;
					var day = agoData.getDate();
					mon=mon<10? '0'+mon:mon;
					day=day<10? '0'+day:day;
					var date=year+'-'+mon+'-'+day;
				return date;
			},
			// 获取时间相关数据
			changeTime(){
				var dateArr=[]
				for(var i=this.date_select;i>=1;i--){
					// var type=i==this.date_select?'start':i==1?'end':''
					var date=this.getBeforeDate(i)
					dateArr.push(date)
				}
				this.dateArr=dateArr;
			},
			// 时间切换
			switchTime(){
				this.loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				// 时间轴更新
				this.changeTime()
				// 渲染echarts
				if(this.web!='opensea'){
					this.echartsInitAll(2)
				}else{
					this.loading.close();
				}
			},
			// 打开选择盘口
			openPopCollect(){
				this.popexchange=this.exchange
				this.popweb=this.web
				this.popyenUnit1=this.yenUnit1
				this.popyenUnit2=this.yenUnit2
				this.popweb_id=this.web_id

				this.popCollection=true
			},
			// 常用填充
			commonlyTc(item){
				this.popweb=item.web;
				this.popyenUnit1=item.currency.split('_')[0];
				this.popyenUnit2=item.currency.split('_')[1];
				this.popexchange=item.exchange_name;
				this.popweb_id=item.web_id;
				this.handleInConfirm()
			},
			// 确认进入盘口
			handleInConfirm(){
				// 非空验证
				if(!this.popweb){
					this.$message({
						message:this.$t('tip.selectExchange')+'！',
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
				}
				else if(this.popweb!='opensea'){
					if(!this.popyenUnit1||!this.popyenUnit2){
						this.$message({
							message:this.$t('tip.enterPair')+'！',
							type: 'error',
							center: true,
							customClass: 'shotMsg',
						});
					}else{
						this.exchange=this.popexchange
						this.web=this.popweb
						this.yenUnit1=this.popyenUnit1
						this.yenUnit2=this.popyenUnit2
						this.web_id=this.popweb_id
						this.handleIn();

						this.popCollection=false;
					}
				}else{
					// opensea
					if(!this.popyenUnit1){
						this.$message({
							message:this.$t('tip.enterCollect')+'！',
							type: 'error',
							center: true,
							customClass: 'shotMsg',
						});
					}else{
						this.$confirm(this.$t('data.nftWait'), this.$t('tip.tips'), {
							confirmButtonText: this.$t('common.confirm'),
							// showConfirmButton:false,
							center: true,
							showClose: false,
							customClass: 'deleteOk',
							showCancelButton:false,
						}).then(() => {})
						
						// this.exchange=this.popexchange
						// this.web=this.popweb
						// this.yenUnit1=this.popyenUnit1
						// this.yenUnit2=this.popyenUnit2
						// this.web_id=this.popweb_id
						// this.handleIn_nft();

						// this.popCollection=false;
					}
				}
			},
			// 进入盘口请求数据-非nft
			handleIn(){
				this.loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				// 渲染echarts
				this.echartsInitAll(3)
			},
			// 进入盘口请求数据-nft
			handleIn_nft(){},
			// 获取收藏列表
			getCollectList(){
				this.$store.dispatch('markets/getCollectList')
			},
			// 取消收藏
			collectCancel(currency,web_id,web){
				collectCancel({
					account:this.account,
					currency,
					web_id,
					web
				}).then(res=>{
					this.$message({
						message: this.$t('tip.cancelCollectionSuc'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					this.getCollectList()
				})
			},
			// 交易所
			closeExchange() {
				this.popExchange = false;
			},
			handleSelect(data) {
				this.popexchange = data.text;
				this.popweb = data.web;
				this.popweb_id=data.web_id
				
				this.popExchange = false;
				// 初始化
				// this.yenUnit1=this.yenUnit2='';
				this.isIn = false;
			},

			async initChartBar() {
				var now=new Date().getTime();
				var ago=now-86400000;//一天的毫秒数为86400000
				var agoData= new Date(ago);
				var year = agoData.getFullYear();
				var mon = agoData.getMonth() + 1;
				var day = agoData.getDate();
				mon=mon<10? '0'+mon:mon;
				day=day<10? '0'+day:day;
				var date=year+'-'+mon+'-'+day;
				
				// let myChart = echarts.init(document.getElementById('chartbar'));
				let myChart = this.myChart1 = this.$echarts.init(this.$refs.chartbar, 'dark');
				var color=['rgb(246,70,93)','rgb(240,185,11)','rgb(14,203,129)']
				let option = {
					// color: ['#FF4D4F','#FDDD60','#5AD8A6'],
					color,
					backgroundColor: 'transparent',
					// title: {
					// 	// text: that.$t('data.dailyTrading')+'('+that.timeRange+')',//'每日交易量(2021-12)'
					// 	text: ['{a|'+that.$t('data.dailyTrading')+'}{b|'+'（'+that.$t('data.endTime')+date+'）'+'}'],//'每日交易量(2021-12)'
					// 	textStyle: {
					// 		color: this.theme=='light'?'#333':'#b9b8ce',
					// 		// fontSize: '14px',
					// 		// fontWeight: 'normal',
					// 		rich: {
					// 			a: {
					// 				fontSize: 14,
					// 				fontWeight: 'normal'
					// 			},
					// 			b: {
					// 				fontSize: 11,
					// 				fontWeight: 'normal'
					// 			}
					// 		}
					// 	},
					// 	top: 10,
					// 	left: 10,
					// },
					tooltip: {
						trigger: 'axis',
						backgroundColor: '#282C33',
						textStyle: {
							color: '#aaa',
							fontSize: '12'
						},
						formatter:(params)=>{
							// console.log(params)
							var html=`${params[0].name}<br />`
							params.forEach((item,index)=>{
								html+=`
								<div style="display:flex;align-items:center;justify-content: space-between;">
									<div style="margin-right:20px">
										<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>
										${item.seriesName}
									</div>

									<span style="margin-right:3px">${item.data}</span>
									${index==0||index==2?this.coin_unit:this.gas_unit}
								</div>`
							})
							return html
						},
					},
					legend: {
						data:[that.$t('data.buy'),that.$t('data.gas'),that.$t('data.sell')],
						itemWidth: 8,
						itemHeight: 8,
						borderRadius: 0,
						left: 'right',
						// top: 40,
						// top: 10,
						padding: 10,
						textStyle: {
							color: '#aaa',
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						// top:'70px',
						containLabel: true
					},
					xAxis: [
						{
							type: 'category',
							axisLine: {
								show: false,
							},
							axisTick: false,
							data: that.dateArr,
							axisLabel: {
								color: '#aaa',
								formatter:function (value, index) {
									var dateArr=value.split('-')
									return Number(dateArr[1])+'.'+ Number(dateArr[2]);
								}
							},
						}
					],
					yAxis: [
						{
							type: 'value',
							min: '0',
							// max: '5000',
							axisLine: {
								show: false,
							},
							splitLine: {
								show: false,
							},
							label: {
								normal: {
									show: true,
									position: 'top',
								}
							},
							axisLabel: {
								color: '#aaa',
							},
						}
					],
					series: [
						{
							name: that.$t('data.buy'),//'买单',
							type: 'bar',
							stack: 'Ad',
							emphasis: {
								focus: 'series'
							},
							data: that.buyData,
						},
						{
							name: that.$t('data.gas'),//'gas',
							type: 'bar',
							stack: 'Ad',
							emphasis: {
								focus: 'series'
							},
							data: that.gasData,
							barCategoryGap:'40%',
						},
						{
							name: that.$t('data.sell'),//'卖单',
							type: 'bar',
							stack: 'Ad',
							emphasis: {
								focus: 'series'
							},
							data: that.sellData,
							barCategoryGap:'40%',
						},
					]
				};
				
				myChart.setOption(option);
				window.addEventListener('resize', () => {
					myChart.resize();
				});

				return true
			},
			async initChartCircle() {
				let myChart = this.myChart2 = this.$echarts.init(this.$refs.chartcircle, 'dark');
				let option = {
					color: ['#1890FF', '#5AD8A6', '#5D7092', '#F6BD16'],
					backgroundColor: 'transparent',
					title: {
						text: that.$t('data.allocation'),//'资金分配',
						textStyle: {
							color: this.theme=='light'?'#333':'#b9b8ce',
							fontSize: '14px',
							fontWeight: 'normal'
						},
						top: 10,
						left: 10,
					},
					tooltip: {
						trigger: 'item',
						backgroundColor: '#282C33',
						textStyle: {
							color: '#aaa'
						},
						formatter:(item)=>{
							var name=item.data.name;
							var num=item.data.num;
							var value=item.data.value;

							var html=`
								<div><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>${name}</div>
								<div style="display:flex;align-items:center;">
									<div style="margin-right:15px">${that.$t('common.proportion')}:</div>
									<div>${item.percent}%</div>
								</div>
								<div style="display:flex;align-items:center;">
									<div style="margin-right:15px">${that.$t('common.num')}:</div>
									<div>${num}</div>
								</div>
								<div style="display:flex;align-items:center;">
									<div style="margin-right:15px">${that.$t('common.valuation')}:</div>
									<div>${value} <span style="font-size:10px">USD</span></div>
								</div>
							`
							return html
						}
					},
					legend: {
						orient: 'vertical',
						right: '5%',
						top: 'center',
						itemWidth: 8,
						itemHeight: 8,
						textStyle: {
							color: '#aaa'
						}
					},
					series: [
						{
							name: that.$t('data.allocation'),//'资金分配',
							type: 'pie',
							radius: ['40%', '70%'],
							avoidLabelOverlap: false,
							label: {
								// show: false,
								// position: 'center',
								position: 'inner',
								// color:'#777',
								color:'#fff',
								// {a|{b}}{b|{d}}%
								formatter:'{d}%',
								// rich:{
								// 	a:{
								// 		width:'50%',
								// 		align:'left',
								// 	},
								// 	b:{
								// 		width:'50%',
								// 		align:'right',
								// 	}
								// }
								// function(params){
								// 	return `<span style="margin-right:15px">${params.name}</span><span>${params.percent}</span>`
								// },
							},
							// emphasis: {
							// 	label: {
							// 		show: false,
							// 		fontSize: '40',
							// 		fontWeight: 'bold'
							// 	}
							// },
							labelLine: {
								show: true
							},
							data: that.pieData
						},
					]
				}
				myChart.setOption(option);
				window.addEventListener('resize', () => {
					myChart.resize();
				});

				return true
			},
			async initChartLine() {
				var now=new Date().getTime();
				var ago=now-86400000;//一天的毫秒数为86400000
				var agoData= new Date(ago);
				var year = agoData.getFullYear();
				var mon = agoData.getMonth() + 1;
				var day = agoData.getDate();
				mon=mon<10? '0'+mon:mon;
				day=day<10? '0'+day:day;
				var date=year+'-'+mon+'-'+day;

				let myChart = this.myChart3 = this.$echarts.init(this.$refs.chartline, 'dark');
				var color=['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A']
				let option = {
					color,
					backgroundColor: 'transparent',
					title: {
						// text: that.$t('data.curve')+'('+that.timeRange+')',//'资产曲线',
						text: ['{a|'+that.$t('data.curve')+'}{b|'+'（'+that.$t('data.endTime')+date+'）'+'}'],//'资产曲线',
						textStyle: {
							color: this.theme=='light'?'#333':'#b9b8ce',
							// fontSize: '14px',
							// fontWeight: 'normal',
							rich: {
								a: {
									fontSize: 14,
									fontWeight: 'normal',
									color: this.theme=='light'?'#333':'#b9b8ce',
								},
								b: {
									fontSize: 11,
									fontWeight: 'normal',
									color: this.theme=='light'?'#333':'#b9b8ce',
								}
							}
						},
						top: 10,
						left: 10,
					},
					tooltip: {
						trigger: 'axis',
						backgroundColor: '#282C33',
						textStyle: {
							color: '#aaa',
							fontSize: '12'
						},
						formatter:(params)=>{
							var html=`${params[0].name}<br />`
							params.forEach((item,index)=>{
								html+=`<div style="display:flex;align-items:center;justify-content: space-between;">
									<div style="margin-right:15px">
										<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>
										${item.seriesName}
									</div>
									
									${item.data}
								</div>`
								// ${item.data>0.01?item.data:0}
							})
							return html
						},
					},
					legend: {
						data: that.lineLegendData,
						width: '50%',
						right: 10,
						top: 10,
						itemHeight: 2,
						textStyle: {
							color: '#aaa',
						}
					},
					grid: {
						left: '3%',
						right: '5%',
						bottom: '3%',
						top:'20%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						axisTick: false,
						axisLine: {
							show: false,
						},
						data: that.dateArr,
						axisLabel: {
							color: '#aaa',
							formatter:function (value, index) {
								var dateArr=value.split('-')
								return Number(dateArr[1])+'.'+ Number(dateArr[2]);
							}
						},
					},
					yAxis: {
						// type: that.lineType,
						// type: 'log',
						type: 'value',
						// min: 0.01,
						min: 0,
						// max: 60,
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: '#aaa',
							// show: false,
							// formatter:function (value, index) {
							// 	if(that.lineType=='log'){
							// 		if(value==0.01){
							// 			return 0
							// 		}else{
							// 			return value
							// 		}
							// 	}
							// }
						},
					},
					series: that.lineData,
				}
				myChart.setOption(option);
				window.addEventListener('resize', () => {
					myChart.resize();
				});

				return true
			},

			async getBar(){
				const res=await getVolumeRecords({
					web:this.web,
					currency:this.yenUnit1+'_'+this.yenUnit2,
					account:this.account,
					date_type:this.date_select==7?0:1
				})
				// .then(res=>{
					// console.log(res,'bar')
					if(!res||!res.records||res.records.length<=0){
						if(this.date_select==7){
							this.buyData=[0,0,0,0,0,0,0]
							this.sellData=[0,0,0,0,0,0,0]
							this.gasData=[0,0,0,0,0,0,0]
						}else{
							this.buyData=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
							this.sellData=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
							this.gasData=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
						}
					}else{
						this.coin_unit=res.coin_unit
						this.gas_unit=res.gas_unit
						var buyData=[]
						var sellData=[]
						var gasData=[]
						this.dateArr.forEach((item,index) => {
							var buy_volume=0
							var sell_volume=0
							var gas_buy_volume=0
							var gas_sell_volume=0

							try{
								res.records.forEach(el=>{
									// var dateArr=el.date.split('-');
									// var date=dateArr[1]+'.'+dateArr[2]
									// console.log(item,el)
									if(item==el.date){
										buy_volume=el.buy_volume
										sell_volume=el.sell_volume
										gas_buy_volume=el.gas_buy_volume
										gas_sell_volume=el.gas_sell_volume
										throw('hasData')
									}
								})
							}catch(err){}
							// buyData.push(buy_volume)
							// sellData.push(sell_volume)
							// gasData.push(Number(gas_buy_volume)+Number(gas_sell_volume))
							// 保留6位小数
							buyData.push(Math.round(buy_volume*1000000)/1000000)
							sellData.push(Math.round(sell_volume*1000000)/1000000)
							gasData.push(Math.round((Number(gas_buy_volume)+Number(gas_sell_volume))*1000000)/1000000)
						});
						this.buyData=buyData
						this.sellData=sellData
						this.gasData=gasData
					}
					this.$nextTick(()=>{
						this.initChartBar().then(res=>{
							return true
						})
					})
				// }).catch(err=>{
				// 	this.loading.close()
				// })
			},
			async getPie(){
				const res =await getAssetsRatio({
					web:this.web,
					currency:this.yenUnit1+'_'+this.yenUnit2,
					account:this.account,
				})
				// .then(res=>{
					// console.log(res,'pie')
					var pieData=[];
					var len=res.length
					var num=0
					res.forEach(item=>{
						if(item.token_price_u==0&&item.token_num==0){
							num++
						}
						pieData.push({
							value:item.token_price_u==0?null:item.token_price_u,
							name:item.token,
							num:item.token_num,
						})
					})
					if(num==len){
						this.pieNoData=1
					}else{
						this.pieNoData=0
					}
					this.pieData=pieData
					this.$nextTick(()=>{
						this.initChartCircle().then(res=>{
							return true
						})
					})
				// }).catch(err=>{
				// 	this.loading.close()
				// })
			},
			async getLine(){
				const res=await getAssetsRecord({
					web:this.web,
					currency:this.yenUnit1+'_'+this.yenUnit2,
					account:this.account,
					date_type:this.date_select==7?0:1
				})
				// .then(res=>{
					// console.log(res,'line')
					this.lineLegendData=res.token_list;

					if(!res||!res.assets_record||res.assets_record.length<=0){
						this.lineType='value';
						var lineData=[]
						res.token_list.forEach(item=>{
							var data=[]
							if(this.date_select==7){
								data=[0,0,0,0,0,0,0]
							}else{
								data=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
							}
							lineData.push({
								name: item,
								type: 'line',
								data
							})
						})
						this.lineData=lineData
					}else{
						// this.lineType='log';
						// this.lineType='value';

						var lineData=[]
						res.token_list.forEach(name=>{
							var obj={
								name,
								type: 'line',
								data:[],
							}
							this.dateArr.forEach((item,index) => {
								// var num=0.01//补值
								var num=0//补值
								try{
									res.assets_record.forEach(el=>{
										// var dateArr=el.date.split('-');
										// var date=dateArr[1]+'.'+dateArr[2]
										if(item==el.date){
											num=el.assets[name]?el.assets[name]:0
											throw('hasData')
										}
									})
								}catch(err){}
								obj.data.push(num)
							})
							lineData.push(obj)
						});
						this.lineData=lineData
					}


					this.$nextTick(()=>{
						this.initChartLine().then(res=>{
							return true
						})
					})
				// }).catch(err=>{
				// 	this.loading.close()
				// })
			},
			echartsInitAll(num){
				if(num==2){
					// init bar、line
					Promise.all([that.getBar(),that.getLine()]).then(()=>{
						this.loading.close()
					}).catch(()=>{
						this.loading.close()
					})
				}else if(num==3){
					// init all
					Promise.all([that.getBar(),that.getPie(),that.getLine()]).then(()=>{
						this.loading.close()
					}).catch(()=>{
						this.loading.close()
					})
				}
			},
		},
		watch: {
			lang(){
				this.myChart1.clear();
				this.initChartBar();
				
				this.myChart2.clear();
				this.initChartCircle();
				
				this.myChart3.clear();
				this.initChartLine();
			},
			theme(){
				this.myChart1.clear();
				this.initChartBar();
				
				this.myChart2.clear();
				this.initChartCircle();
				
				this.myChart3.clear();
				this.initChartLine();
			},
		},
		beforeDestroy() {
			if(timer){
				clearInterval(timer)
				timer=''
			}
		}
	})
</script>
