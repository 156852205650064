// 数据报表相关接口
// ,http_no_aes,http_longtime,http_no_aes_longtime
import {http} from '@/utils/http'

// 获取账号交易成交量历史记录接口
export const getVolumeRecords = data => http('/Trade/volume_records',data,'post')
// 账号资产占比统计接口
export const getAssetsRatio = data => http('/Wallet/assets_ratio_stat',data,'post')
// 账号资产历史记录接口
export const getAssetsRecord = data => http('/Wallet/assets_record',data,'post')
// 导出交易明细
export const exportRecordsSend = data => http('/Trade/export_records_send',data,'post')